import { ColumnType } from 'antd/es/table';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import BaseTable, { Column, RowKey, unflatten } from 'react-base-table';
import 'react-base-table/styles.css';
import { useWindowSize } from 'react-use';
import { IObjectWithId } from 'utils/store/MetaStore';

import { EmptyMarker } from 'ui';
import { Loader } from 'ui/Loader/Loader';

import './BaseTable.css';

type TColumnType = ColumnType<IObjectWithId>;

type BaseTableProps = {
    className?: string;
    loading?: boolean;
    columns: TColumnType[];
    footerRender?: () => React.ReactNode;
    titleRender?: () => React.ReactNode;
    data: IObjectWithId[];
    selectedRows?: IObjectWithId[];
    onRowSelectionChange?: (selectedRows: IObjectWithId[]) => void;
    selectable?: boolean;
    defaultSortOrder?: {
        descend?: string[];
        ascend?: string[];
    };
    doubleClickable?: boolean;
    onDoubleClick?: (row: any, index?: number) => () => void;
    editable?: boolean;
    viewMode?: 'inline' | 'modal';
    handleDataSourceChanged?: (row: IObjectWithId) => void;
    scroll: Record<string, any>;
};

type CellRendererFunctionProps = {
    cellData: any;
    column: Column;
    rowData: any;
    rowIndex: number;
    isScrolling: boolean;
    selectedIdsMap: Record<string, boolean>;
};

// type CellRendererFunction = (props: ) => React.ReactNode;

// Memoized cell component to prevent unnecessary renders
const MemoizedCellRenderer = memo(
    ({
        cellData,
        column,
        rowData,
        rowIndex,
        isScrolling,
        cellRerender,
        onChange
    }: Record<string, any>) => {
        if (!column.render) {
            return null;
        }

        return column.render(
            cellData,
            rowData as IObjectWithId,
            rowIndex,
            onChange
        ) as React.ReactNode;
    },
    (prevProps, nextProps) => {
        const prevRerender = prevProps?.cellRerender;
        const nextRerender = nextProps?.cellRerender;

        const noNeedRerender =
            prevProps.rowIndex === nextProps.rowIndex && prevRerender === nextRerender;

        // Prevents re-render if cellData and other relevant props don't c hange
        return noNeedRerender;
    }
);

const ROW_HEIGHT = 38;
const ROWS_KEY_COLUMN_NAME = 'Id';

import { isFunction } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import { SmartTooltip } from 'smart/ui';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

function flattenTree(allRowsWithChildren: any[]) {
    let result: any[] = [];

    function recursiveFlatten(rows: any[]) {
        rows.forEach((row) => {
            result.push(row); // Add the current row
            if (row.children && row.children.length > 0) {
                recursiveFlatten(row.children); // Recursively process the children
            }
        });
    }

    recursiveFlatten(allRowsWithChildren); // Start recursion with the root array
    return result;
}

// const ExpandIcon = (props) => {

//     return (
//         <>
//             <BaseTableExpandIcon {...props}/>
//         </>
//     )
//     // console.log('expand props:', props);

//     if (!props.expandable) {
//         return null;
//     }

//     return props.expanding ? (
//         <MinusOutlined
//             onClick={() => {
//                 debugger;
//                 props.onExpand(false);
//             }}
//         />
//     ) : (
//         <PlusOutlined
//             onClick={() => {
//                 debugger;
//                 props.onExpand(true);
//             }}
//         />
//     );
// }; //<Loader depth={rest.depth} /> : <BaseTableExpandIcon {...rest} />
// TODO: надо бы причесать
export const Table = ({
    columns,
    data,
    footerRender,
    titleRender,
    loading,
    selectable,
    selectedRows,
    onRowSelectionChange,
    onDoubleClick,
    doubleClickable,
    defaultSortOrder = { descend: ['CreatedAt'] },
    editable = false,
    viewMode = 'inline',
    scroll,
    handleDataSourceChanged
}: BaseTableProps) => {
    const { t } = useTranslation();

    const { height: windowHeight, width: windowWidth } = useWindowSize();

    const parentRef = useRef<HTMLDivElement | null>(null);
    const tableRef = useRef(null);

    const [parentWidth, setParentWidth] = useState<number>(0);

    const [selectedIdsMap, setSelectedIdsMap] = useState<Record<string, boolean>>({});

    const [rowSorting, setRowSorting] = useState<{
        [keys: string]: 'ascend' | 'descend' | undefined;
    }>();

    const [cellRerender, setCellRerender] = useState(false);

    const getInitialRowSorting = useCallback(() => {
        const res = {} as { [keys: string]: 'ascend' | 'descend' | undefined };

        defaultSortOrder?.descend?.forEach((fieldName) => {
            res[fieldName] = 'descend';
        });
        defaultSortOrder?.ascend?.forEach((fieldName) => {
            res[fieldName] = 'ascend';
        });

        return res;
    }, [defaultSortOrder?.ascend, defaultSortOrder?.descend]);

    useEffect(() => {
        if (!rowSorting || !Object.keys(rowSorting).length) {
            setRowSorting(getInitialRowSorting());
        }
    }, [getInitialRowSorting, rowSorting]);

    const setSelectedRowMap = (rows: IObjectWithId[]) => {
        if (!rows) {
            return;
        }

        const map: Record<string, boolean> = {};

        for (let i = 0; i < rows?.length; i++) {
            map[rows[i].Id] = true;
        }

        setSelectedIdsMap(map);
    };

    useEffect(() => {
        setSelectedRowMap(selectedRows || []);
    }, [selectedRows]);

    const selectionCellRenderer = useCallback(
        (props: CellRendererFunctionProps) => {
            const { rowData, container } = props;

            const rowId = rowData[ROWS_KEY_COLUMN_NAME];

            const selectedIds = container?.props?.rowProps?.selectedIdsMap || selectedIdsMap;
            let innerSelectedRows = container?.props?.rowProps?.selectedRows || selectedRows;
            const checked = selectedIds[rowId];

            return (
                <div>
                    <Checkbox
                        type="checkbox"
                        checked={checked || undefined}
                        onChange={(e) => {
                            const checked = e.target.checked;

                            if (checked) {
                                if (innerSelectedRows && onRowSelectionChange) {
                                    onRowSelectionChange([...innerSelectedRows, rowData]);
                                } else {
                                    setSelectedIdsMap((prev) => {
                                        const newSelectedIdMap = {
                                            ...prev,
                                            [rowId]: true
                                        };

                                        return newSelectedIdMap;
                                    });
                                }
                            } else {
                                if (innerSelectedRows && onRowSelectionChange) {
                                    const newSelected = innerSelectedRows.filter(
                                        (row) => row[ROWS_KEY_COLUMN_NAME] !== rowId
                                    );

                                    onRowSelectionChange(newSelected);
                                    setSelectedRowMap(newSelected); //!!
                                } else {
                                    setSelectedIdsMap((prev) => {
                                        const prevSelection = { ...prev };
                                        prevSelection[rowId] = false;
                                        return prevSelection;
                                    });
                                }
                            }
                        }}
                    />
                </div>
            );
        },
        [selectedIdsMap]
    );

    // Use effect to listen for parent size changes
    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            if (parentRef.current) {
                setParentWidth(parentRef.current.getBoundingClientRect().width);
            }
        });

        if (parentRef.current) {
            resizeObserver.observe(parentRef.current);
            setParentWidth(parentRef.current.getBoundingClientRect().width); // initial width
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    // Memoize the columns to avoid recalculating if columns haven't changed
    const memoizedColumns = useMemo(() => {
        let leftOffset = selectable ? 40 : 0; // Accumulate width for left-sticky columns
        let rightOffset = 0; // Accumulate width for right-sticky columns
        // const totalWidth = columns.reduce(
        //     (acc, col) => (acc + (col.width as number) ? (col.width as number) : 100),
        //     0
        // );

        return columns.map((col) => {
            if (!col.width) {
                col.width = 100;
            }

            // If the column is fixed to the left, calculate the left position
            if (col.fixed === 'left') {
                const styles = {
                    position: 'sticky',
                    left: leftOffset,
                    zIndex: 2,
                    backgroundColor: '#f8f8f8',
                    borderRight: '1px solid #d9d9d9'
                };
                leftOffset += col.width as number; // Add this column's width to the left offset
                return { ...col, styles };
            }

            // If the column is fixed to the right, calculate the right position
            if (col.fixed === 'right') {
                const styles = {
                    position: 'sticky',
                    right: rightOffset,
                    zIndex: 2,
                    backgroundColor: '#f8f8f8',
                    borderLeft: '1px solid #d9d9d9'
                };
                rightOffset += col.width as number; // Add this column's width to the right offset
                return { ...col, styles };
            }

            return col;
        });
    }, [columns]);

    const scrollOptions = useMemo(() => {
        // 100% - 22*13em
        const y = windowHeight - 20 * 13.48;
        const x = parentWidth;

        // console.log('scroll:', scroll);
        return {
            y: y,
            x,
            ...scroll
        };
    }, [columns.length, windowHeight, scroll, parentWidth]);

    const renderOverlay = () => {
        if (loading)
            return (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                        margin: '0',
                        width: '100%',
                        height: '100%'
                    }}
                >
                    <Loader />
                </div>
            );

        return null;
    };

    const sortedData = useMemo(() => {
        if (rowSorting) {
            const sortedFields = Object.keys(rowSorting);

            if (sortedFields.length > 0) {
                // умеем сортировать только по одному полю
                const sortedField = sortedFields[0];

                const sortedColumn = columns.find((col) => col.dataIndex === sortedField);

                if (
                    sortedColumn?.sorter?.getCompare &&
                    isFunction(sortedColumn?.sorter?.getCompare as any)
                ) {
                    const compareFunction = sortedColumn?.sorter?.getCompare();

                    if (isFunction(compareFunction)) {
                        return data.sort((a, b) => {
                            let aGreaterThenB = compareFunction(a, b);

                            if (rowSorting[sortedField] === 'descend') {
                                aGreaterThenB = aGreaterThenB * -1;
                            }

                            return aGreaterThenB;
                        });
                    }
                }
            }
        }

        return data;
    }, [data, rowSorting, columns]);

    const handleSort = useCallback(
        (
            fieldName: string,
            sorting: {
                [keys: string]: 'ascend' | 'descend' | undefined;
            },
            order?: 'asc' | 'desc' | undefined
        ) => {
            if (order) {
                setRowSorting({
                    [fieldName]: order === 'asc' ? 'ascend' : 'descend'
                });
                return;
            }
            let newRowSorting: {
                [keys: string]: 'ascend' | 'descend' | undefined;
            } = {};

            const FIRST_DEFAULT_SORTING_WHEN_CLICK = 'ascend';
            const SECOND_DEFAULT_SORTING_WHEN_CLICK = 'descend';

            if (sorting) {
                const currentSorting = sorting[fieldName];

                if (currentSorting) {
                    if (currentSorting === FIRST_DEFAULT_SORTING_WHEN_CLICK) {
                        newRowSorting = {
                            [fieldName]: SECOND_DEFAULT_SORTING_WHEN_CLICK
                        };
                    }

                    if (currentSorting === SECOND_DEFAULT_SORTING_WHEN_CLICK) {
                        // TODO: сбросить на дефолтную?
                        // там выше сбрасывается, тут норм
                        newRowSorting = {};
                    }
                } else {
                    newRowSorting = {
                        [fieldName]: FIRST_DEFAULT_SORTING_WHEN_CLICK
                    };
                }
            } else {
                newRowSorting = {
                    [fieldName]: FIRST_DEFAULT_SORTING_WHEN_CLICK
                };
            }

            setRowSorting(newRowSorting);
        },
        [rowSorting, setRowSorting]
    );

    useEffect(() => {
        setCellRerender((prev) => !prev);
    }, [rowSorting, sortedData, editable]);

    // useEffect(() => {
    //     console.log('cellRerender changed:', cellRerender);
    // }, [cellRerender]);

    const rowEventHandlers = {
        onDoubleClick:
            doubleClickable && onDoubleClick
                ? ({
                      rowData,
                      rowIndex,
                      rowKey
                  }: {
                      rowData: IObjectWithId;
                      rowIndex: number;
                      rowKey: RowKey;
                  }) => {
                      const doubleClickFunction = onDoubleClick(rowData, rowIndex);
                      doubleClickFunction();
                  }
                : undefined
    };

    const expandColumnKey =
        memoizedColumns && memoizedColumns.length > 0 ? memoizedColumns[0].key : undefined;

    return (
        <div style={{ width: '100%', overflow: 'hidden' }} ref={parentRef}>
            {titleRender ? (
                <div style={{ backgroundColor: 'white', padding: '8px' }}>{titleRender()}</div>
            ) : (
                <></>
            )}

            <BaseTable
                data={sortedData}
                fixed={memoizedColumns.length > 6}
                ref={tableRef}
                disabled={loading}
                width={scrollOptions.x}
                rowProps={{
                    selectedIdsMap,
                    selectedRows,
                    rowSorting,
                    cellRerender,
                    handleDataSourceChanged
                }}
                rowKey={ROWS_KEY_COLUMN_NAME}
                expandColumnKey={expandColumnKey}
                height={data.length <= 7 ? ROW_HEIGHT + data.length * ROW_HEIGHT : scrollOptions.y}
                overscanRowCount={25}
                overlayRenderer={renderOverlay}
                rowHeight={ROW_HEIGHT}
                rowEventHandlers={rowEventHandlers}
                headerHeight={ROW_HEIGHT}
                // components={{
                //     ExpandIcon: ExpandIcon
                // }}
            >
                {selectable && (
                    <Column
                        width={40}
                        key="__selection__"
                        className="base-table-select-cell"
                        headerClassName="base-table-header-cell base-table-select-cell"
                        headerRenderer={({ container }) => {
                            return (
                                <>
                                    <Checkbox
                                        type="checkbox"
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                let allRows = container.props?.data || data;

                                                allRows = flattenTree(allRows);

                                                if (onRowSelectionChange) {
                                                    onRowSelectionChange(
                                                        allRows as IObjectWithId[]
                                                    );
                                                } else {
                                                    setSelectedRowMap(allRows as IObjectWithId[]);
                                                }
                                            } else {
                                                if (onRowSelectionChange) {
                                                    onRowSelectionChange([]);
                                                } else {
                                                    setSelectedIdsMap({});
                                                }
                                            }
                                        }}
                                    />
                                </>
                            );
                        }}
                        cellRenderer={(props) => {
                            return <>{selectionCellRenderer(props)}</>;
                        }}
                    />
                )}
                {memoizedColumns.map((column, i) => (
                    <Column
                        key={column.key as string}
                        title={column.title as string}
                        dataKey={column.dataIndex as string}
                        style={{
                            ...column.styles
                        }}
                        hidden={column.hidden}
                        flexGrow={
                            memoizedColumns.length < 6 &&
                            column?.width &&
                            (column?.width as number) > 100
                                ? 1
                                : undefined
                        }
                        headerClassName={'base-table-header-cell'}
                        width={(column.width as number) || 100}
                        headerRenderer={({ column, container }) => {
                            return (
                                <div
                                    onClick={() => {
                                        const currentRowSorting =
                                            container?.props?.rowProps?.rowSorting || rowSorting;

                                        handleSort(column.dataKey as string, currentRowSorting);
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'stretch',
                                        width: '100%',
                                        userSelect: 'none'
                                        // maxHeight: ROW_HEIGHT
                                    }}
                                >
                                    {/* <div> */}

                                    {/* <SmartTooltip> */}
                                    <span
                                        style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            wordBreak: 'keep-all',
                                            whiteSpace: 'nowrap' // Prevent line breaks,
                                        }}
                                        title={column.title}
                                    >
                                        {column.title}
                                    </span>
                                    {/* </SmartTooltip> */}
                                    {/* </div> */}
                                    <div style={{ marginLeft: '8px', userSelect: 'none' }}>
                                        <span
                                            style={{
                                                fontSize: '0.6rem',
                                                opacity: '0.5',

                                                color: container?.props?.rowProps?.rowSorting
                                                    ? container?.props?.rowProps?.rowSorting[
                                                          column.dataKey
                                                      ] === 'ascend'
                                                        ? 'blue'
                                                        : 'gray'
                                                    : 'gray'
                                            }}
                                        >
                                            ▲
                                        </span>
                                        <span
                                            style={{
                                                fontSize: '0.6rem',
                                                opacity: '0.5',

                                                color: container?.props?.rowProps?.rowSorting
                                                    ? container?.props?.rowProps?.rowSorting[
                                                          column.dataKey
                                                      ] === 'descend'
                                                        ? 'blue'
                                                        : 'gray'
                                                    : 'gray'
                                            }}
                                        >
                                            ▼
                                        </span>
                                    </div>
                                </div>
                            );
                        }}
                        // Use memoized cell renderer
                        cellRenderer={(props) => {
                            const rerender = props?.container?.props?.rowProps?.cellRerender;

                            if (
                                editable &&
                                viewMode === 'inline' &&
                                column &&
                                column?.onCell &&
                                props.rowData
                            ) {
                                const row = props.rowData;
                                const rowIndex = props.rowIndex;
                                const columnEditRender = column?.onCell(row as unknown, rowIndex);

                                const onChangeFunc =
                                    props?.container?.props?.rowProps?.handleDataSourceChanged;

                                if (
                                    columnEditRender &&
                                    columnEditRender.render &&
                                    columnEditRender.onTableDataChange &&
                                    isFunction(columnEditRender.render) &&
                                    onChangeFunc &&
                                    isFunction(onChangeFunc)
                                ) {
                                    return (
                                        <div style={{ width: '100%' }}>
                                            {columnEditRender.render(
                                                props.cellData,
                                                props.rowData as IObjectWithId,
                                                rowIndex,
                                                (paramName, paramValue) => {
                                                    onChangeFunc({
                                                        ...row,
                                                        [paramName]: paramValue
                                                    });
                                                }
                                            )}
                                        </div>
                                    );
                                }
                            }

                            return (
                                <MemoizedCellRenderer
                                    {...props}
                                    column={column}
                                    cellRerender={rerender}
                                />
                            );
                        }}
                    />
                ))}
            </BaseTable>
            {/* )} */}

            {data.length === 0 && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: ROW_HEIGHT,
                        backgroundColor: 'none'
                    }}
                >
                    <EmptyMarker size="small" noImage />
                </div>
            )}
            {footerRender ? (
                <div style={{ backgroundColor: 'white', padding: '8px' }}>{footerRender()}</div>
            ) : (
                <></>
            )}
        </div>
    );
};
